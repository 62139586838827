var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.getCurrentCoordinate}},[_vm._v(" Standort abfragen ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Bezeichnung","rules":[_vm.rules.required],"loading":_vm.isLoadingMapGeocode,"required":""},model:{value:(_vm.editedItem.label),callback:function ($$v) {_vm.$set(_vm.editedItem, "label", $$v)},expression:"editedItem.label"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Radius (in Meter)","type":"number"},model:{value:(_vm.editedItem.properties.radius),callback:function ($$v) {_vm.$set(_vm.editedItem.properties, "radius", _vm._n($$v))},expression:"editedItem.properties.radius"}})],1)],1),_c('v-row',[_c('v-col',[_c('GmapMap',{ref:"googleMapRef",staticStyle:{"width":"100%","height":"350px"},attrs:{"map-type-id":_vm.$store.state.map.type,"zoom":_vm.mapProperties.defaultZoom,"center":_vm.mapProperties.defaultCenter,"options":{
            mapTypeControl: true,
            streetViewControl: false,
            styles: _vm.mapProperties.defaultStyle,
          }}},[_c('GmapMarker',{attrs:{"position":_vm.markerPosition,"clickable":true,"draggable":true},on:{"dragend":_vm.changeCoordinateHandler}}),_c('GmapCircle',{attrs:{"center":_vm.markerPosition,"radius":parseFloat(_vm.editedItem.properties.radius),"visible":true,"options":{
              fillColor: 'blue',
              fillOpacity: 0.1,
              strokeOpacity: 0,
            }}}),_vm._l((_vm.stempelgeraete),function(stempelgeraet){return _c('GmapCircle',{key:'circle' + stempelgeraet.docid,attrs:{"center":{
              lat: stempelgeraet.geometry.coordinates[1],
              lng: stempelgeraet.geometry.coordinates[0],
            },"radius":parseFloat(stempelgeraet.properties.radius),"visible":true,"options":{
              fillColor: 'blue',
              fillOpacity: 0.1,
              strokeOpacity: 0,
            }}})})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Latitude","value":_vm.markerPosition.lat,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Longitude","value":_vm.markerPosition.lng,"disabled":""}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isNotValidForm,"color":"primary","text":""},on:{"click":_vm.saveObject}},[_vm._v(" Speichern ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" dark @click="getCurrentCoordinate">
            Standort abfragen
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="editedItem.label"
            label="Bezeichnung"
            :rules="[rules.required]"
            :loading="isLoadingMapGeocode"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model.number="editedItem.properties.radius"
            label="Radius (in Meter)"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <GmapMap
            ref="googleMapRef"
            style="width: 100%; height: 350px"
            :map-type-id="$store.state.map.type"
            :zoom="mapProperties.defaultZoom"
            :center="mapProperties.defaultCenter"
            :options="{
              mapTypeControl: true,
              streetViewControl: false,
              styles: mapProperties.defaultStyle,
            }"
          >
            <GmapMarker
              :position="markerPosition"
              :clickable="true"
              :draggable="true"
              @dragend="changeCoordinateHandler"
            ></GmapMarker>
            <GmapCircle
              :center="markerPosition"
              :radius="parseFloat(editedItem.properties.radius)"
              :visible="true"
              :options="{
                fillColor: 'blue',
                fillOpacity: 0.1,
                strokeOpacity: 0,
              }"
            ></GmapCircle>
            <GmapCircle
              v-for="stempelgeraet in stempelgeraete"
              :key="'circle' + stempelgeraet.docid"
              :center="{
                lat: stempelgeraet.geometry.coordinates[1],
                lng: stempelgeraet.geometry.coordinates[0],
              }"
              :radius="parseFloat(stempelgeraet.properties.radius)"
              :visible="true"
              :options="{
                fillColor: 'blue',
                fillOpacity: 0.1,
                strokeOpacity: 0,
              }"
            ></GmapCircle>
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Latitude"
            :value="markerPosition.lat"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Longitude"
            :value="markerPosition.lng"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isNotValidForm"
        color="primary"
        text
        @click="saveObject"
      >
        Speichern
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  props: {
    structure: Object,
  },

  computed: {
    stempelgeraete() {
      return this.$store.state.objects.objects.filter(
        (obj) => obj.objectType == "Stempelgerät"
      );
    },

    markerPosition() {
      if (this.marker) {
        return {
          lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
          lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
        };
      }
      if (this.currentCoordinate) {
        return this.currentCoordinate;
      }
      return {
        lat: this.editedItem.geometry.coordinates[1],
        lng: this.editedItem.geometry.coordinates[0],
      };
    },

    isNotValidForm() {
      if (!this.editedItem.label) return true;
      return false;
    },
  },

  data() {
    return {
      counter: 0,
      marker: null,
      editedItem: {
        geometry: {
          coordinates: [6.447762, 51.15777],
          type: "Point",
        },
        label: "",
        objectType: "Stempelgerät",
        properties: {
          radius: 50,
        },
      },
      isLoadingMapGeocode: false,
      currentCoordinate: null,
      mapProperties: {
        defaultZoom: 14,
        defaultCenter: { lat: 51.15777, lng: 6.447762 },
        defaultStyle: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  methods: {
    changeCoordinateHandler(position) {
      this.marker = position;
      const latLng = {
        lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
        lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
      };
      if (this.counter == 0) {
        this.getReverseGeocodedAdress(latLng);
      }
    },

    saveObject() {
      const data = {
        geometry: {
          coordinates: [
            parseFloat(this.markerPosition.lng),
            parseFloat(this.markerPosition.lat),
          ],
          type: "Point",
        },

        label: this.editedItem.label,
        objectType: "Stempelgerät",
        properties: {
          radius: this.editedItem.properties.radius,
        },
        readOnly: false,
        source: "manuell",
      };

      this.$store.dispatch("createObject", {
        data,
        firebaseCollectionPath: this.structure.firebaseCollectionPath,
      });
      this.$emit("closeDialog");
    },

    getCurrentCoordinate() {
      if ("geolocation" in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentCoordinate = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.mapProperties.defaultCenter = this.currentCoordinate;
            if (this.counter == 0) {
              this.getReverseGeocodedAdress(this.currentCoordinate);
            }
          },
          (error) => {
            console.error(error.message);
            this.$store.commit("showSnackbar", {
              message: "Unbekannte Fehler!",
              color: "red",
            });
          },
          { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
        );
      } else {
        this.$store.commit("showSnackbar", {
          message: "Geolocation nicht verfügbar!",
          color: "red",
        });
      }
    },

    getGeocode(val) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: val }, (results, status) => {
        if (status == "OK") {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          this.currentCoordinate = { lat, lng };
          this.mapProperties.defaultCenter = { lat, lng };
          this.counter++;
        } else {
          console.error(status);
        }
      });
    },

    getReverseGeocodedAdress(latLng) {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: latLng })
        .then(({ results }) => {
          console.log(results[0]);
          const street = results[0].address_components.find((component) =>
            component.types.includes("route")
          )?.long_name;
          const number = results[0].address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name;
          const postalCode = results[0].address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const city = results[0].address_components.find((component) => {
            return ["locality", "political"].every((value) => {
              return component.types.includes(value);
            });
          })?.long_name;
          const address = [street, number, postalCode, city];
          this.markerPostalCode = postalCode;
          this.markerCity = city;
          if (address.every((value) => value)) {
            this.editedItem.label = `${street} ${number}`;
          } else {
            this.editedItem.label = results[0].formatted_address;
          }
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isLoadingMapGeocode = false;
          this.counter++;
        });
    },
  },

  watch: {
    "editedItem.label": debounce(function (val) {
      if (val.length > 5 && this.counter == 0) {
        this.getGeocode(val);
      }
    }, 1000),
  },
};
</script>

<template>
  <div>
    <v-card-text>
      <v-row v-if="!isEdit">
        <v-col>
          <v-btn color="primary" dark @click="getCurrentCoordinate">
            Standort abfragen
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isEdit">
        <v-col>
          <v-select
            :items="$store.getters.writePermissionStructure"
            item-text="label"
            return-object
            v-model="editedItem.mainCategory"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="editedItem.label"
            label="Bezeichnung"
            :rules="[rules.required]"
            :loading="isLoadingMapGeocode"
            required
            :disabled="isEdit"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            v-model="editedItem.properties.typ"
            label="Typ"
            :items="structure.submenu"
            :disabled="isEdit"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="editedItem.properties.totalAmount"
            label="Anzahl der Ladepunkte"
            type="number"
            :disabled="isEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Gepl. Umsetzungsdatum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formatDate"
                :disabled="isEdit"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedItem.properties.date"
              @input="menu = false"
              locale="de-DE"
              :min="new Date().toISOString()"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <GmapMap
            ref="googleMapRef"
            style="width: 100%; height: 350px"
            :map-type-id="$store.state.map.type"
            :zoom="mapProperties.defaultZoom"
            :center="mapProperties.defaultCenter"
            :options="{
              mapTypeControl: true,
              streetViewControl: false,
              styles: mapProperties.defaultStyle,
            }"
          >
            <GmapMarker
              :position="markerPosition"
              :clickable="true"
              :draggable="true"
              @dragend="changeCoordinateHandler"
            ></GmapMarker>
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Latitude"
            :value="markerPosition.lat"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Longitude"
            :value="markerPosition.lng"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isNotValidForm"
        color="primary"
        text
        @click="saveObject"
      >
        Speichern
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment";
//import { Timestamp } from "@firebase/firestore";

export default {
  props: {
    structure: Object,
    object: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      counter: 0,
      editedItem: {
        geometry: {
          coordinates: [6.447762, 51.15777],
          type: "Point",
        },
        label: "",
        objectType: "",
        mainCategory: "",
        status: "",
        properties: {
          radius: 50,
          description: "",
          date: null,
          type: "",
          totalAmount: 0,
          typ: "",
        },
      },
      marker: null,
      markerPostalCode: "",
      markerCity: "",
      isLoadingMapGeocode: false,
      currentCoordinate: null,
      mapProperties: {
        defaultZoom: 14,
        defaultCenter: { lat: 51.15777, lng: 6.447762 },
        defaultStyle: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      rules: {
        required: (value) => !!value || "Required",
      },
      editedCategory: null,
    };
  },

  watch: {
    object: {
      handler: function (v) {
        if (v) {
          this.editedItem = Object.assign({}, v);
        }
      },
      immediate: true,
    },
  },

  computed: {
    markerPosition() {
      if (this.marker) {
        return {
          lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
          lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
        };
      }
      if (this.currentCoordinate) {
        return this.currentCoordinate;
      }
      return {
        lat: this.editedItem.geometry.coordinates[1],
        lng: this.editedItem.geometry.coordinates[0],
      };
    },

    isNotValidForm() {
      if (!this.editedItem.label) {
        return true;
      }
      if (!this.editedItem.properties.typ) {
        return true;
      }
      if (this.editedItem.properties.totalAmount == 0) {
        return true;
      }
      if (this.isEdit) {
        if (
          !this.editedItem.mainCategory.firebaseCollectionPath ||
          this.editedItem.mainCategory.label == this.object.mainCategory
        ) {
          return true;
        }
      }
      return false;
    },

    formatDate() {
      return this.editedItem.properties.date
        ? moment(this.editedItem.properties.date).locale("de").format("L")
        : "";
    },
  },

  methods: {
    getCurrentCoordinate() {
      if ("geolocation" in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentCoordinate = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.mapProperties.defaultCenter = this.currentCoordinate;
            if (this.counter == 0) {
              this.getReverseGeocodedAdress(this.currentCoordinate);
            }
          },
          (error) => {
            console.error(error.message);
            this.$store.commit("showSnackbar", {
              message: "Unbekannte Fehler!",
              color: "red",
            });
          },
          { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
        );
      } else {
        this.$store.commit("showSnackbar", {
          message: "Geolocation nicht verfügbar!",
          color: "red",
        });
      }
    },

    changeCoordinateHandler(position) {
      this.marker = position;
      const latLng = {
        lat: parseFloat(this.marker.latLng.lat().toFixed(6)),
        lng: parseFloat(this.marker.latLng.lng().toFixed(6)),
      };
      if (this.counter == 0) {
        this.getReverseGeocodedAdress(latLng);
      }
    },

    getReverseGeocodedAdress(latLng) {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: latLng })
        .then(({ results }) => {
          console.log(results[0]);
          const street = results[0].address_components.find((component) =>
            component.types.includes("route")
          )?.long_name;
          const number = results[0].address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name;
          const postalCode = results[0].address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const city = results[0].address_components.find((component) => {
            return ["locality", "political"].every((value) => {
              return component.types.includes(value);
            });
          })?.long_name;
          const address = [street, number, postalCode, city];
          this.markerPostalCode = postalCode;
          this.markerCity = city;
          if (address.every((value) => value)) {
            this.editedItem.label = `${street} ${number}`;
          } else {
            this.editedItem.label = results[0].formatted_address;
          }
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isLoadingMapGeocode = false;
          this.counter++;
        });
    },

    saveObject() {
      const data = {
        city: this.markerCity,
        geometry: {
          coordinates: [
            parseFloat(this.markerPosition.lng),
            parseFloat(this.markerPosition.lat),
          ],
          type: "Point",
        },
        label: this.editedItem.label,
        mainCategory: this.structure.label,
        objectType: `${this.structure.label} - ${this.editedItem.properties.typ}`,
        postal_code: this.markerPostalCode,
        properties: {
          totalAvailableAmount: 0,
          //  date: this.editedItem.properties.date
          //    ? Timestamp.fromDate(new Date(this.editedItem.properties.date))
          //    : "",
          date: this.editedItem.properties.date
            ? this.editedItem.properties.date
            : "",
          type: this.editedItem.properties.typ == "Ladesäule" ? "AC" : "DC",
          typ: this.editedItem.properties.typ,
          totalAmount: parseInt(this.editedItem.properties.totalAmount),
        },
      };

      if (this.structure.label == "In Planung") {
        if (this.editedItem.properties.typ == "Ladesäule") {
          data.properties.totalACAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20AC.png?alt=media&token=1c8015a5-c27e-4317-bda5-fcaec382a519";
        } else {
          data.properties.totalDCAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Planung.png?alt=media&token=1c03d061-c0b9-4f62-a3ee-3f3a21397e00";
        }
      } else {
        if (this.editedItem.properties.typ == "Ladesäule") {
          data.properties.totalACAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20AC.png?alt=media&token=1c8015a5-c27e-4317-bda5-fcaec382a519";
        } else {
          data.properties.totalDCAmount = parseInt(
            this.editedItem.properties.totalAmount
          );
          data.icon =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Umsetzung.png?alt=media&token=00ce4494-f68d-476a-acbf-a4f0bb64852d";
        }
      }

      let newFirebaseCollectionPath = this.structure.firebaseCollectionPath;
      if (this.isEdit) {
        let oldFirebaseCollectionPath =
          this.$store.getters.writePermissionStructure.find(
            (structure) => this.object.mainCategory == structure.label
          ).firebaseCollectionPath;
        console.log(oldFirebaseCollectionPath);
        newFirebaseCollectionPath =
          this.editedItem.mainCategory.firebaseCollectionPath;

        const docid = this.object.docid ? this.object.docid : this.object.id;
        this.$store.dispatch("deleteObject", {
          docid: docid,
          firebaseCollectionPath: oldFirebaseCollectionPath,
        });

        data.mainCategory = this.editedItem.mainCategory.label;
        data.objectType;
        if (this.editedItem.properties.typ == "Schnellladesäule") {
          if (this.editedItem.mainCategory.label == "In Planung") {
            data.objectType = "In Planung - Schnellladesäule";
            data.icon =
              "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Planung.png?alt=media&token=1c03d061-c0b9-4f62-a3ee-3f3a21397e00";
          } else {
            data.objectType = "In Umsetzung - Schnelladesäule";
            data.icon =
              "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2FLadepunkt%20DC%20Umsetzung.png?alt=media&token=00ce4494-f68d-476a-acbf-a4f0bb64852d";
          }
        } else {
          if (this.editedItem.mainCategory.label == "In Planung") {
            data.objectType = "In Planung - Ladesäule";
          } else {
            data.objectType = "In Umsetzung - Ladesäule";
          }
        }
      }

      this.$store.dispatch("createObject", {
        data,
        firebaseCollectionPath: newFirebaseCollectionPath,
      });
      this.$emit("closeDialog");
      //  console.log(newFirebaseCollectionPath, data);
    },
  },
};
</script>

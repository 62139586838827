<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-card-title>
        Objekt bearbeiten
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="isChargingStation">
        <Ladeinfrastruktur
          :object="object"
          :isEdit="true"
          :structure="structure"
          @closeDialog="closeDialog"
        />
      </v-card-text>
      <v-card-text v-if="isStempelgerät">
        <Stempelgeräte
          :object="object"
          :isEdit="true"
          :structure="structure"
          @closeDialog="closeDialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import Stempelgeräte from "./stempelgeräte.vue";
import Ladeinfrastruktur from "./ladeinfrastruktur.vue";

export default {
  components: { Stempelgeräte, Ladeinfrastruktur },

  props: {
    active: Number,
    object: Object,
  },

  data() {
    return {
      dialogVisible: false,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  computed: {
    isChargingStation() {
      return this.object?.objectType.toLowerCase().includes("ladesäule");
    },
    isStempelgerät() {
      return this.object?.objectType.toLowerCase().includes("stempelgerät");
    },

    structure() {
      return this.$store.getters.writePermissionStructure.find((structure) =>
        this.object?.mainCategory.includes(structure.label)
      );
    },
  },

  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },
};
</script>
  